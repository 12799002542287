let colorPool = [
  "#f32f7d",
  "#00447b",
  "#939393",
  "#006ec7",
  "#00a60a",
  "#a6104c",
  "#fff245",
  "#ff7918",
  "#00213b",
  "#2ff33a",
  "#077892",
  "#99a2aa",
  "#00cefb",
  "#ff4b93",
  "#b2a81e",
  "#45ff4f",
  "#0e5287",
  "#b31152",
  "#e56d16",
  "#00bf0c",
  "#2b6491",
  "#535353",
  "#008bfb",
  "#9a406e",
  "#fff195",
  "#75f37d",
  "#17363b",
  "#a6799b",
  "#ffc090",
  "#b1ff9a"
]

let colorAssignments = {}

function randomColor() {
  return (
    "hsl(" +
    Math.floor(360 * Math.random()) +
    "," +
    Math.ceil(Math.random() * 30 + 70) +
    "%," +
    Math.floor(Math.random() * 20 + 30) +
    "%)"
  )
}

export const getColorForStrings = strings => {
  return strings.map(string => {
    if (string && Object.prototype.hasOwnProperty.call(colorAssignments, string)) {
      return colorAssignments[string]
    }

    return (colorAssignments[string] = colorPool.length ? colorPool.shift() : randomColor())
  })
}
