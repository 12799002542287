<template lang="pug">
  div.analytics-chart
    div.analytics-chart-loading(v-show="loading")
      div: Icon(icon="spinner", spin)
      div Loading&hellip;
    div.analytics-chart-error(v-show="!loading && error")
      div {{ error }}
    div.analytics-chart-body(v-show="!loading")
      div.analytics-chart-canvas: canvas(ref="canvas")
      p.analytics-chart-data-toggle(v-show="data && !empty"): a(@click="toggleData()") {{ showData ? 'Hide' : 'Show' }} Legend
      p.analytics-chart-data-empty(v-show="empty") No data to display yet.
      div.analytics-chart-data(v-show="data && showData && !empty")
        div.analytics-chart-data-item(v-for="item in data" :key="item.label")
          span.square(:style="{backgroundColor: item.color}")
          div.analytics-chart-data-body
            span.label {{ item.label }}
            span.count ({{ item.count }})
</template>

<script>
import Chart from "chart.js"
import { get } from "lodash-es"
import { getColorForStrings } from "../Colors"

export default {
  props: {
    type: {
      type: String,
      required: true
    },
    endpoint: {
      type: String,
      required: true
    },
    params: {
      type: Object
    }
  },
  data: () => ({
    loading: false,
    chart: false,
    data: false,
    error: false,
    empty: false,
    showData: false
  }),
  watch: {
    params: {
      deep: true,
      handler: "reload"
    }
  },
  computed: {
    pieChartOptions: () => ({
      type: "doughnut",
      data: {},
      options: {
        maintainAspectRatio: false,
        spanGaps: false,
        legend: {
          display: false,
          labels: {
            filter: function (legend, data) {
              return data.datasets[0].data[legend.index] > 0
            }
          }
        }
      }
    }),
    lineChartOptions: () => ({
      type: "line",
      data: {},
      options: {
        maintainAspectRatio: false,
        spanGaps: false,
        responsive: true,
        hoverMode: "index",
        stacked: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true
              }
            }
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                display: true
              },
              ticks: {
                beginAtZero: true,
                userCallback: function (label) {
                  // when the floored value is the same as the value we have a whole number
                  if (Math.floor(label) === label) {
                    return label
                  }
                }
              }
            }
          ]
        }
      }
    })
  },
  mounted() {
    this.reload()
  },
  methods: {
    toggleData() {
      this.showData = !this.showData
    },
    reload() {
      this.loading = true
      this.error = false
      this.empty = false

      this.$api
        .get(this.endpoint, { params: this.params })
        .then(
          ({ data }) => {
            let chartData = data

            // Line
            if (this.type == "line") {
              chartData.datasets = chartData.datasets.map(dataset => {
                dataset.fill = true
                dataset.backgroundColor = "rgba(31, 77, 141, 0.1)"
                dataset.borderColor = "rgba(31, 77, 141, 1)"
                dataset.borderWidth = 2
                dataset.lineTension = 0.35
                return dataset
              })

              this.empty = !chartData.labels || !chartData.labels.length
            }

            // Pie
            if (this.type == "pie") {
              let colors = getColorForStrings(chartData.labels)

              chartData.datasets = chartData.datasets.map(dataset => {
                dataset.backgroundColor = colors
                return dataset
              })

              this.data = chartData.labels.map((label, idx) => {
                return {
                  label,
                  count: chartData.datasets[0].data[idx],
                  color: colors[idx]
                }
              })

              this.empty = !chartData.datasets.find(dataset => dataset.data.find(v => v > 0))

              if (this.empty) {
                chartData = {
                  labels: ["No Data"],
                  datasets: [{ label: "Content By Media Type", data: [1], backgroundColor: "#edf2f7" }]
                }
              }
            }

            // Build the chart
            if (!this.chart) {
              let chartOptions = this.type == "pie" ? { ...this.pieChartOptions } : { ...this.lineChartOptions }
              chartOptions.data = chartData
              this.chart = new Chart(this.$refs.canvas.getContext("2d"), chartOptions)
            } else {
              this.chart.data = chartData
              this.chart.update()
            }
          },
          error => {
            this.error = get(error, "response.data.error") || "An error has occured loading the data"
          }
        )
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style>
.analytics-chart {
  &-canvas {
    &,
    canvas {
      height: 300px;
    }
  }

  &-data {
    @apply mx-auto mt-8 grid grid-cols-2 gap-6;

    &-empty,
    &-toggle {
      @apply mt-6 text-center;
    }

    &-empty {
      @apply text-supplement;
    }

    &-item {
      @apply flex;
    }

    .square {
      @apply mr-3 mt-1 block h-4 w-4 flex-shrink-0 rounded-full;
    }

    .count {
      @apply ml-2 text-sm text-supplement;
    }
  }
}
</style>
